/**
 * Determines the text and border colors based on the specified alarm color code.
 *
 * @param {Number} color - The alarm color code.
 * @returns {Object} - An object containing text and border color classes.
 * @property {String} text - The text color class.
 * @property {String} border - The border color class.
 */
export const alarmColors = (color) => {
	const colorMapping = {
		1: {
			text: 'text-green-600 dark:text-green-500 ',
			border: 'border-green-600 dark:border-green-500 ',
		},
		2: {
			text: 'text-red-600 dark:text-red-500 ',
			border: 'border-red-600 dark:border-red-500 ',
		},
		3: { text: 'text-yellow-400 ', border: 'border-yellow-400 ' },
		4: { text: 'text-purple-500 ', border: 'border-purple-500 ' },
		5: { text: 'text-orange-500 ', border: 'border-orange-500 ' },
		6: { text: 'text-blue-500 ', border: 'border-blue-500 ' },
		7: { text: 'text-gray-500 ', border: 'border-gray-500 ' },
	}

	return colorMapping[color] || { text: '', border: '' }
}

/**
 * Determines the alarm state and related information based on device attributes.
 *
 * @param {Function} t - The translation function for localization.
 * @param {Object} device - The device object.
 * @returns {Object} - An object containing alarm-related properties.
 * @property {Number} alarm - The alarm state code.
 * @property {String} alarmColor - The color class for displaying the alarm state.
 * @property {Array<Object>} alarmText - An array of alarm text objects.
 * @property {String|null} setSpecialDevices - The special devices status, if applicable (e.g., 'offline', 'alarm', 'warning').
 */
export const alarmLogic = (t, device) => {
	const alarmTimestampHours = 48
	const attr = device.attributes

	const calculateSinceOfflineHours = () => {
		const userTime = new Date(new Date()).getTime()
		const inHours =
			new Date(attr.last_timestamp).getTime() +
			alarmTimestampHours * 60 * 60 * 1000
		return (
			Math.floor((inHours - userTime) / (60 * 60 * 1000)) * -1 +
			alarmTimestampHours
		)
	}

	const setAlarmProperties = (
		alarm,
		alarmColor,
		setSpecialDevices,
		alarmText
	) => {
		return {
			alarm,
			alarmColor,
			setSpecialDevices,
			alarmText: Array.isArray(alarmText)
				? alarmText
				: JSON.parse(alarmText),
		}
	}

	// Device is disabled
	if (device.status === 'disabled') {
		return setAlarmProperties(6, 'text-blue-500', 'offline', [
			{ color: '2', text: t('alarms.notActive'), isUsecase: false },
		])
	}

	// Device is GW and offline
	if (
		device.typeId === 1 &&
		!(attr.connected === 'True' || attr.connected === 'true')
	) {
		return setAlarmProperties(
			2,
			'text-red-600 dark:text-red-500',
			'offline',
			[
				{
					color: '2',
					text: t('gateway.text.offline'),
				},
			]
		)
	}

	if (!attr.last_timestamp || attr.last_timestamp === '0') {
		return setAlarmProperties(2, 'text-gray-500', 'offline', [
			{ color: '3', text: t('alarms.neverSent'), isUsecase: false },
		])
	}

	const sinceOfflineHours = calculateSinceOfflineHours()

	if (sinceOfflineHours > alarmTimestampHours) {
		return setAlarmProperties(2, 'text-gray-500', 'offline', [
			{
				color: '3',
				text: t('alarms.offline', {
					hours: sinceOfflineHours,
					days: Math.floor(sinceOfflineHours / 24),
				}),
				isUsecase: false,
			},
		])
	}

	if (attr.app_color === '2') {
		return setAlarmProperties(
			2,
			'text-red-600 dark:text-red-500',
			'alarm',
			attr.app_alarm_text || []
		)
	}

	if (attr.app_color === '3') {
		return setAlarmProperties(
			3,
			'text-yellow-400',
			'warning',
			attr.app_alarm_text || []
		)
	}

	if (attr.app_color === '5') {
		return setAlarmProperties(
			4,
			'text-yellow-400',
			'warning',
			attr.app_alarm_text || []
		)
	}

	return setAlarmProperties(
		1,
		'text-green-600 dark:text-green-500',
		undefined,
		attr.app_alarm_text || []
	)
}
